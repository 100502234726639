<template>
  <ItemGrid
    :columns="columns"
    :items="items"
    mode="BROWSE"
    :no-actions="true"
    @select="handleSelect"
  >
    <!-- <template #avatar="{ item }">
      <div class="row items-center justify-center" style="width: 36px">
        <BaseIcon :name="item.icon" :color="item.color" />
      </div>
    </template> -->

    <!-- <template v-for="stat in stats" #[stat]="{ value }">
      <div :key="stat" class="stat" @click.stop="goto(stat)">
        <div class="ellipsis">
          {{ value || "-" }}
        </div>
      </div>
    </template> -->
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";

export default {
  name: "SearchGrid",

  components: { ItemGrid },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    handleSelect(groupId) {
      this.$emit("select", groupId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
