var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AppLayout',[_c('ViewWrapper',{attrs:{"title":"Search Results","breadcrumbs":[],"tab":"","tabs":[]},on:{"quickHelp":_vm.showQuickHelp},scopedSlots:_vm._u([{key:"header-title",fn:function(){return [(_vm.searchInputs.SearchValue || _vm.searchText)?_c('div',{staticClass:"chip search-word"},[_c('div',{staticClass:"label"},[_vm._v(" Search by: "+_vm._s(_vm.searchInputs.SearchValue || _vm.searchText)+" ")]),_c('BaseIcon',{staticClass:"cursor-pointer",attrs:{"name":"mdi-close-circle","inherit-color":""},on:{"click":_vm.clearGroupBy}})],1):_vm._e()]},proxy:true}])},[_c('QuickHelp',{model:{value:(_vm.quickHelp),callback:function ($$v) {_vm.quickHelp=$$v},expression:"quickHelp"}}),_c('div',{attrs:{"id":"search"}},[_c('Portal',{attrs:{"to":"action-bar"}},[(_vm.showItemActions)?_c('ItemActions',{attrs:{"item":"search","columns":_vm.columns,"group-by":_vm.groupBy,"order":"","filter-by":[],"criteria":"","active-view":"","hide-actions":[
            'activeView',
            'export',
            'filter',
            'group',
            'sort',
            'toggle',
            'search',
          ],"simple-search-filter":true},on:{"update:columns":function($event){_vm.columns=$event},"update:groupBy":function($event){_vm.groupBy=$event},"update:group-by":function($event){_vm.groupBy=$event},"refresh":_vm.getRefresh,"search":_vm.searchRow}},[_c('template',{slot:"filterSearchIcon"},[_c('div',{staticClass:"is-expanded q-mr-sm",attrs:{"id":"filter-search"}},[_c('BaseActionButton',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Filter'),expression:"'Filter'",modifiers:{"top":true}}],staticClass:"filter q-mr-sm",attrs:{"is-flat":"","no-border":"","icon":"mdi-filter-plus-outline","color":"gray"}},[_c('q-menu',{attrs:{"transition-show":"scale","transition-hide":"scale"}},_vm._l((_vm.menus),function(column){return _c('ListItem',{key:column.id,attrs:{"icon":column.isSelectMenu
                        ? 'mdi-checkbox-marked'
                        : 'mdi-checkbox-blank-outline',"is-selected":column.isSelectMenu,"label":column.label},on:{"click":function($event){column.isSelectMenu = !column.isSelectMenu}}})}),1)],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],ref:"expandableSearch",attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.searchText)},on:{"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}),_c('BaseIcon',{staticClass:"icon",attrs:{"name":"eva-search"}})],1)])],2):_vm._e()],1),_c('div',{staticClass:"content"},[(_vm.activeView === 'LIST')?_c('SearchList',{attrs:{"items":_vm.items,"columns":_vm.columns},on:{"select":_vm.goto}}):_vm._e(),(_vm.activeView === 'GRID')?_c('SearchGrid',{attrs:{"items":_vm.items,"columns":_vm.columns},on:{"select":_vm.goto}}):_vm._e()],1),_c('Pagination',{attrs:{"total-items":_vm.totalItems,"current-page":_vm.currentPage,"items-per-page":_vm.itemsPerPage},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}}})],1),_c('FileSheet',{attrs:{"file":_vm.selectFile,"repository-field":_vm.repositoryField,"options":{}},on:{"show-files":function($event){_vm.isFileSheetVisible = true}},model:{value:(_vm.isFileSheetVisible),callback:function ($$v) {_vm.isFileSheetVisible=$$v},expression:"isFileSheetVisible"}}),_c('RequestProcessSheet',{attrs:{"workflow-request-list":_vm.workflowTicketList,"process-id":_vm.processId,"workflow":_vm.workflows,"workflow-id":_vm.workflowId,"form-id":_vm.formId,"form":_vm.forms,"enable-controls":_vm._enableControls}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }