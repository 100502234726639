<template>
  <AppLayout>
    <ViewWrapper
      title="Search Results"
      :breadcrumbs="[]"
      tab=""
      :tabs="[]"
      @quickHelp="showQuickHelp"
    >
      <template #header-title>
        <div
          v-if="searchInputs.SearchValue || searchText"
          class="chip search-word"
        >
          <div class="label">
            Search by: {{ searchInputs.SearchValue || searchText }}
          </div>

          <BaseIcon
            name="mdi-close-circle"
            inherit-color
            class="cursor-pointer"
            @click="clearGroupBy"
          />
        </div>
      </template>

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->
      <div id="search">
        <Portal to="action-bar">
          <ItemActions
            v-if="showItemActions"
            item="search"
            :columns.sync="columns"
            :group-by.sync="groupBy"
            order=""
            :filter-by="[]"
            criteria=""
            active-view=""
            :hide-actions="[
              'activeView',
              'export',
              'filter',
              'group',
              'sort',
              'toggle',
              'search',
            ]"
            :simple-search-filter="true"
            @refresh="getRefresh"
            @search="searchRow"
          >
            <template slot="filterSearchIcon">
              <div id="filter-search" class="is-expanded q-mr-sm">
                <BaseActionButton
                  v-tooltip.top="'Filter'"
                  is-flat
                  no-border
                  icon="mdi-filter-plus-outline"
                  color="gray"
                  class="filter q-mr-sm"
                >
                  <q-menu transition-show="scale" transition-hide="scale">
                    <!-- anchor="bottom end"
                    self="top right" -->
                    <!-- menus -->
                    <!-- <BaseScrollbar height="calc(100vh - 276px)" class="q-pb-sm"> -->
                    <ListItem
                      v-for="column in menus"
                      :key="column.id"
                      :icon="
                        column.isSelectMenu
                          ? 'mdi-checkbox-marked'
                          : 'mdi-checkbox-blank-outline'
                      "
                      :is-selected="column.isSelectMenu"
                      :label="column.label"
                      @click="column.isSelectMenu = !column.isSelectMenu"
                    />
                    <!-- </BaseScrollbar> -->

                    <!-- ... -->
                  </q-menu>
                </BaseActionButton>

                <input
                  ref="expandableSearch"
                  v-model="searchText"
                  type="text"
                  placeholder="Search..."
                />
                <BaseIcon name="eva-search" class="icon" />
              </div>
            </template>
          </ItemActions>
        </Portal>

        <!-- child routes -->

        <div class="content">
          <SearchList
            v-if="activeView === 'LIST'"
            :items="items"
            :columns="columns"
            @select="goto"
          />

          <SearchGrid
            v-if="activeView === 'GRID'"
            :items="items"
            :columns="columns"
            @select="goto"
          />
        </div>

        <Pagination
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
        />
      </div>
      <!-- ... -->

      <FileSheet
        v-model="isFileSheetVisible"
        :file="selectFile"
        :repository-field="repositoryField"
        :options="{}"
        @show-files="isFileSheetVisible = true"
      />

      <RequestProcessSheet
        :workflow-request-list="workflowTicketList"
        :process-id="processId"
        :workflow="workflows"
        :workflow-id="workflowId"
        :form-id="formId"
        :form="forms"
        :enable-controls="_enableControls"
      />
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import {
  workspace,
  repository,
  form,
  workflow,
  user,
  group,
  globalSearch,
  report,
} from "@/api/factory.js";
import SearchList from "@/views/search/components/SearchList";
import SearchGrid from "@/views/search/components/SearchGrid";
import DataType from "@/constants/data-type.js";
import { startCase, lowerCase } from "lodash-es";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import { Portal } from "portal-vue";
import Pagination from "@/components/common/display/Pagination.vue";
import FileSheet from "@/views/repositories/views/repository-browse-and-trash/components/FileSheet";
import RequestProcessSheet from "@/views/search/components/RequestProcessSheet";
import ListItem from "@/components/common/ListItem.vue";
import { isEmpty } from "lodash-es";

export default {
  name: "SearchResults",

  components: {
    Portal,
    AppLayout,
    ViewWrapper,
    QuickHelp,
    SearchList,
    SearchGrid,
    ItemActions,
    Pagination,
    FileSheet,
    RequestProcessSheet,
    ListItem,
  },

  props: {
    searchInput: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      quickHelp: false,
      resultData: [],
      groupBy: "Type",
      // columns: [],
      searchResult: [],
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      activeView: "LIST",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      searchResultList: [],
      selectFile: {},
      isFileSheetVisible: false,
      repositoryField: [],
      fileTotalItems: 0,
      workflowTicketList: [],
      selectedProcess: {},
      processId: 0,
      workflows: {},
      workflowId: 0,
      formId: 0,
      forms: {},
      searchQuery: "",
      menus: [
        {
          id: this.$nano.id(),
          label: "Workspace",
          value: "Workspace",
          isSelectMenu: false,
        },
        {
          id: this.$nano.id(),
          label: "Folder",
          value: "Folders",
          isSelectMenu: false,
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Task",
        //   value: "Task",
        // },
        {
          id: this.$nano.id(),
          label: "Form",
          value: "Form",
          isSelectMenu: false,
        },
        {
          id: this.$nano.id(),
          label: "Workflow",
          value: "WorkFlow",
          isSelectMenu: false,
        },
        {
          id: this.$nano.id(),
          label: "User",
          value: "User",
          isSelectMenu: false,
        },
        {
          id: this.$nano.id(),
          label: "Group",
          value: "Group",
          isSelectMenu: false,
        },
        {
          id: this.$nano.id(),
          label: "Menu",
          value: "Menu",
          isSelectMenu: false,
        },
        {
          id: this.$nano.id(),
          label: "Document",
          value: "Documents",
          isSelectMenu: false,
        },
        {
          id: this.$nano.id(),
          label: "Content",
          value: "content",
          isSelectMenu: true,
        },
        {
          id: this.$nano.id(),
          label: "Request No",
          value: "Request No",
          isSelectMenu: false,
        },
      ],
      searchInputs: {},
      searchText: "",
      showItemActions: false,
    };
  },

  computed: {
    items() {
      if (this.resultData.length) {
        return this.resultData.map(({ key, value }) => ({
          key: this.groupBy,
          value: key,
          data: value.map((workspace) => ({
            ...workspace,
            link: true,
            icon: "eva-search",
          })),
        }));
      }
      return this.searchResult.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((result) => ({
          ...result,
          link: true,
          icon: this.getMenuIcon(result.menu),
        })),
      }));
    },

    columns() {
      let column = [];
      if (this.resultData.length) {
        if (this.resultData[0].value.length) {
          for (var key in this.resultData[0].value[0]) {
            if (key !== "id")
              column.push({
                id: this.$nano.id(),
                name: key,
                label: startCase(lowerCase(key)),
                type: key == "name" ? "TITLE" : "NORMAL",
                isVisible: true,
                isSortable: false,
                isGroupable: false,
                dataType: DataType.SHORT_TEXT,
              });
          }
        } else {
          return column;
        }
        return column;
      } else {
        column.push({
          id: this.$nano.id(),
          name: "searchName",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        });
        column.push({
          id: this.$nano.id(),
          name: "menu",
          label: "Type",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: false,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        });
        column.push({
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        });
        column.push({
          id: this.$nano.id(),
          name: "date",
          label: "Date",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        });
        column.push({
          id: this.$nano.id(),
          name: "score",
          label: "Score",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        });
        column.push({
          id: this.$nano.id(),
          name: "noOfHits",
          label: "No.of Hits",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        });

        return column;
      }
    },

    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (controls.indexOf(control) < 0) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        console.log(this.$route);
        if (this.$route.query) {
          if (this.$route.query.type) {
            if (this.$route.query.type === "Workspace") {
              this.getWorkspaces(this.$route.query.name);
            } else if (this.$route.query.type === "Folder") {
              this.getRepositories(this.$route.query.name);
            } else if (this.$route.query.type === "Form") {
              this.getForms(this.$route.query.name);
            } else if (this.$route.query.type === "Workflow") {
              this.getWorkflows(this.$route.query.name);
            } else if (this.$route.query.type === "User") {
              this.getUsers(this.$route.query.name);
            } else if (this.$route.query.type === "Group") {
              this.getGroups(this.$route.query.name);
            }
          }
        } else if (this.$route.params.searchInput) {
          this.searchInputs = this.searchInput;
          console.log(this.searchInputs);

          this.getGlobalSearch();
        }
      },
    },

    "$store.state.searchInput": function () {
      // console.log(this.$store.state.searchInput);
      if (this.$store.state.searchInput) {
        this.searchInputs = this.$store.state.searchInput;
        this.getGlobalSearch();
      }
    },

    menus: {
      deep: true,
      handler() {
        let menu = this.menus.filter((item) => item.isSelectMenu);
        // console.log(menu);
        let name = [];
        menu.forEach((item) => {
          name.push(item.value);
        });

        this.getGlobalSearch(name.length ? name : [""]);
      },
    },

    searchText() {
      if (this.searchText) {
        if (this.searchText.length >= 2) {
          let menu = this.menus.filter((item) => item.isSelectMenu);
          // console.log(menu);
          let name = [];
          menu.forEach((item) => {
            name.push(item.value);
          });

          this.searchInputs.SearchValue = this.searchText;

          this.getGlobalSearch(name.length ? name : [""]);
        }
      }
    },
  },

  created() {},

  mounted() {
    this.$watch(
      (vm) => [vm.mode, vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getRefresh(),
      {
        immediate: true,
        deep: true,
      }
    );
  },

  beforeRouteUpdate(to, from, next) {
    console.log(to, from, next);
  },

  methods: {
    getRefresh() {
      this.showItemActions = true;
      if (this.searchInput) {
        this.searchInputs = this.searchInput;
        this.getGlobalSearch();
      } else {
        if (this.$route.query.type) {
          if (this.$route.query.type === "Workspace") {
            this.getWorkspaces(this.$route.query.name);
          } else if (this.$route.query.type === "Folder") {
            this.getRepositories(this.$route.query.name);
          } else if (this.$route.query.type === "Form") {
            this.getForms(this.$route.query.name);
          } else if (this.$route.query.type === "Workflow") {
            this.getWorkflows(this.$route.query.name);
          } else if (this.$route.query.type === "User") {
            this.getUsers(this.$route.query.name);
          } else if (this.$route.query.type === "Group") {
            this.getGroups(this.$route.query.name);
          }
        }
      }
    },

    showQuickHelp() {
      this.quickHelp = true;
    },

    async getWorkspaces(name) {
      let filter = [];

      this.$store.commit("showLoadingBar");

      if (name) {
        filter = [
          {
            id: this.$nano.id(),
            filters: [
              {
                id: this.$nano.id(),
                criteria: "name",
                condition: "IS_EQUALS_TO",
                value: name,
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ];
      }

      const { error, payload } = await workspace.getWorkspaces({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: filter,
        itemsPerPage: 50,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      // console.log(data, meta);
      this.activeView = "LIST";
      this.resultData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async getRepositories(name) {
      let filter = [];

      this.$store.commit("showLoadingBar");

      if (name) {
        filter = [
          {
            id: this.$nano.id(),
            filters: [
              {
                id: this.$nano.id(),
                criteria: "name",
                condition: "IS_EQUALS_TO",
                value: name,
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ];
      }

      const { error, payload } = await repository.getRepositories({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: filter,
        itemsPerPage: 50,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      // console.log(data, meta);

      this.resultData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async getForms(name) {
      let filter = [];

      this.$store.commit("showLoadingBar");

      if (name) {
        filter = [
          {
            id: this.$nano.id(),
            filters: [
              {
                id: this.$nano.id(),
                criteria: "name",
                condition: "IS_EQUALS_TO",
                value: name,
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ];
      }

      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: filter,
        itemsPerPage: 50,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      // console.log(data, meta);

      this.resultData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async getWorkflows(name) {
      let filter = [];

      this.$store.commit("showLoadingBar");

      if (name) {
        filter = [
          {
            id: this.$nano.id(),
            filters: [
              {
                id: this.$nano.id(),
                criteria: "name",
                condition: "IS_EQUALS_TO",
                value: name,
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ];
      }

      const { error, payload } = await workflow.getWorkflows({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: filter,
        itemsPerPage: 0,
        currentPage: 0,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      // console.log(data, meta);

      this.resultData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async getUsers(name) {
      let filter = [];

      this.$store.commit("showLoadingBar");

      if (name) {
        filter = [
          {
            id: this.$nano.id(),
            filters: [
              {
                id: this.$nano.id(),
                criteria: "email",
                condition: "IS_EQUALS_TO",
                value: name,
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ];
      }

      const { error, payload } = await user.getUsers({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: filter,
        itemsPerPage: 50,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      // console.log(data, meta);

      this.resultData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async getGroups(name) {
      let filter = [];

      this.$store.commit("showLoadingBar");

      if (name) {
        filter = [
          {
            id: this.$nano.id(),
            filters: [
              {
                id: this.$nano.id(),
                criteria: "name",
                condition: "IS_EQUALS_TO",
                value: name,
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ];
      }

      const { error, payload } = await group.getGroups({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: filter,
        itemsPerPage: 50,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      // console.log(data, meta);

      this.resultData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async getGlobalSearch(menu) {
      this.$store.commit("showLoadingBarPage");
      let type = this.menus.filter((item) => item.isSelectMenu);
      // console.log(menu);
      let name = [];
      type.forEach((item) => {
        name.push(item.value);
      });
      let menus = [];
      if (menu) {
        menus = menu;
      } else if (type.length) {
        menus = name;
      } else {
        menus = [""];
      }

      let input = {};
      if (menu) {
        input = {
          SearchValue: this.searchText,
          type: menus,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
          fromDate: "",
          toDate: "",
          userId: this.$store.state.session.id,
        };
      } else if (!isEmpty(this.searchInputs)) {
        // console.log("elseif");
        // if (currentPage || itemsPerPage) {
        //   input = {
        //     SearchValue: this.searchInputs.SearchValue,
        //     currentPage: currentPage,
        //     fromDate: this.searchInputs.fromDate,
        //     itemsPerPage: itemsPerPage,
        //     toDate: this.searchInputs.toDate,
        //     type: this.searchInputs.type,
        //   };
        // } else {
        //   input = this.searchInputs;
        // }
        input = this.searchInputs;
        input.itemsPerPage = this.itemsPerPage;
        input.currentPage = this.currentPage;
      } else {
        input = {
          SearchValue: this.searchText,
          type: menus,
          itemsPerPage: this.itemsPerPage,
          currentPage: this.currentPage,
          fromDate: "",
          toDate: "",
          userId: this.$store.state.session.id,
        };
      }
      // this.searchInputs = input;

      const { error, payload } = await globalSearch.globalSearch(input);

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$store.commit("hideLoadingBarPage");

      console.log(payload);

      let res = payload;
      // console.log((res));
      this.totalItems = payload.meta.totalItems;

      if (res.data) {
        console.log(res.data);
        if (res.data.length) {
          let searchResult = [];
          // this.searchResult = res.resultJson;
          res.data.forEach((result) => {
            searchResult.push({
              menu: result.type,
              searchName:
                result.type === "Documents" ? result.ifileName : result.name,
              idJson: result.id,
              id: this.$nano.id(),
              description: result.description
                ? result.description
                : result.type,
              requestNo: result.requestNo,
              date: result.modifiedDateandtime
                ? this.$day.format(result.modifiedDateandtime)
                : this.$day.format(result.dateandtime),
              score: result.score,
              noOfHits: result.hits,
            });

            // console.log(JSON.stringify(searchResult));

            // let array = searchResult.reduce(function (acc, obj) {
            //   var key = obj["menu"];
            //   if (!acc[key]) {
            //     // acc[key] = [];
            //     acc.push({
            //       key: acc[key],
            //       value: [],
            //     });
            //   }
            //   // acc[key].push(obj);
            //   acc.push({
            //     key: acc[key],
            //     value: [obj],
            //   });
            //   return acc;
            // }, []);
            // console.log(array);
            // this.searchResult = [
            //   {
            //     key: "",
            //     value: searchResult,
            //   },
            // ];
            // this.searchResultList = [
            //   {
            //     key: "",
            //     value: searchResult,
            //   },
            // ];
          });

          let key = "menu";
          let groupByResult = searchResult.reduce(function (rv, x) {
            let v = key instanceof Function ? key(x) : x[key];
            let el = rv.find((r) => r && r.key === v);
            // console.log(v, el);

            if (el) {
              el.value.push(x);
            } else {
              rv.push({
                key: v,
                value: [x],
              });
            }
            return rv;
          }, []);
          this.searchResult = groupByResult;
          this.searchResultList = groupByResult;
          // console.log(this.searchResult);
        } else {
          this.searchResult = [];
        }
      }
    },

    searchRow(search) {
      if (search) {
        this.searchResult = this.searchResultList.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.searchResult = this.searchResultList;
      }
    },

    goto(id) {
      let result = this.searchResult[0].value.find((item) => item.id === id);
      console.log(result);
      if (result.idJson.itemId && result.menu !== "Request No") {
        this.getRepositoryField(result.idJson.repositoryId);
        this.getFile(result.idJson.repositoryId, result.idJson.itemId);
      } else if (result.idJson.workflowId && result.menu === "Request No") {
        this.getWorkflowData(result.idJson.workflowId, result.idJson.processId);
      } else {
        if (result.menu === "Folders") {
          this.$router.replace({
            name: "repositories-browse",
            query: {
              workspaceId: result.idJson.workspaceId,
              repositoryId: result.idJson.repositoryId,
            },
          });
        } else if (result.menu === "WorkFlow") {
          this.$router.replace({
            name: "workflow-builder",
            params: { id: String(result.idJson.repositoryId) },
          });
        } else if (result.menu === "Menu") {
          this.$router.replace({
            name: result.searchName,
          });
        }
      }
    },

    async getFile(rId, itemId) {
      this.$store.commit("showLoadingBar");

      let input = {
        repositoryId: rId,
        parentNodeId: 0,
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                id: this.$nano.id(),
                criteria: "itemid",
                condition: "IS_EQUALS_TO",
                value: itemId,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
        ],
        currentPage: 1,
        itemsPerPage: 10,
        mode: "BROWSE",
        level: 1,
      };
      const { error, payload } = await repository.getEntities(input);

      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);

      if (payload.data[0].value.length) {
        let data = payload.data[0].value;
        // console.log(data);

        let file = data.find((item) => {
          return item.id === Number(itemId);
        });
        // console.log(file);
        this.selectFile = file;
        this.isFileSheetVisible = true;
      }
    },

    async getRepositoryField(id) {
      const { error, payload } = await repository.getRepositoryField({
        criteria: "repositoryId",
        value: id,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryField = payload;
    },

    async getWorkflowData(id, pId) {
      this.$store.commit("showLoadingBar");
      this.workflowTicketList = [];

      // this.list = [];
      const { error, payload } = await report.getWorkflowData(id, {
        filterBy: [],
        itemsPerPage: 100,
        currentPage: 1,
        mode: "",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          this.workflowTicketList.push({
            ...process,
          });
        });
      });

      console.log(this.workflowTicketList);

      this.selectedProcess = this.workflowTicketList.find(
        (item) => item.processId === Number(pId)
      );

      await this.getWorkflow(id, pId);
    },

    async getWorkflow(id, pId) {
      this.workflowId = id;
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workflow.getWorkflow(id);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // console.log(payload);
      // console.log(payload.wFormId);

      this.workflows = payload;

      this.formId = String(payload.wFormId);
      await this.getForm(pId);
      // let workflowJson = JSON.parse(this.workflow.flowJson);
    },

    async getForm(pId) {
      if (this.formId) {
        this.$store.commit("showLoadingBar");

        const { error, payload } = await form.getForm(this.formId);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }

        let formJson = JSON.parse(payload.formJson);
        this.forms = formJson;
      }

      this.processId = Number(pId);
    },

    getMenuIcon(menu) {
      switch (menu) {
        case "Folders":
          return "eva-archive-outline";
        case "WorkFlow":
          return "o_account_tree";
        case "Task":
          return "eva-cube-outline";
        case "Portal":
          return "eva-globe-outline";
        case "Form":
          return "mdi-text-box-outline";
        case "Settings":
          return "eva-settings-2-outline";
        case "Report":
          return "eva-pie-chart-outline";
        case "Documents":
          return "mdi-file-document-outline";
        case "Menu":
          return "mdi-menu";
        case "Content":
          return "mdi-file-document-outline";
        case "Request No":
          return "o_account_tree";

        default:
          return "o_dashboard";
      }
    },

    clearGroupBy() {
      // this.$router.back();

      this.searchInputs.SearchValue = "";
      this.searchText = "";
      this.searchResult = [];
    },
  },
};
</script>

<style lang="scss" scoped>
#search {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}

#filter-search {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--divider-color);
  border-radius: 36px;
  overflow: hidden;
  transition: width 0.25s ease;

  .icon {
    padding: 0px 8px;
  }

  .filter {
    padding: 8px 8px;
  }

  &.is-expanded {
    width: 240px;
    justify-content: flex-start;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(.is-expanded):hover {
    background-color: var(--hover-bg-color);
  }
}

.chip {
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-right: 8px;
  color: white;

  .label {
    font-weight: 500;
    margin-right: 8px;
  }
}

.search-word {
  background-color: var(--green);
}

.q-menu .border {
  border: 1px solid var(--divider-color) !important;
}
</style>
<style lang="scss">
#item-actions {
  margin-bottom: 10px !important;
}
</style>
