<template>
  <ItemList
    :columns="columns"
    :items="items"
    mode="BROWSE"
    :no-actions="true"
    @select="handleSelect"
  >
    <template #score="{ value }">
      <div>
        <q-circular-progress
          show-value
          font-size="12px"
          :value="value"
          size="lg"
          :thickness="0.22"
          color="primary"
          track-color="grey-3"
        >
          {{ value }}%
        </q-circular-progress>
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";

export default {
  name: "SearchList",

  components: { ItemList },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    handleSelect(groupId) {
      this.$emit("select", groupId);
    },
  },
};
</script>

<style lang="scss" scoped>
#form-status {
  display: flex;
  align-items: center;
  color: var(--green);

  &.draft {
    color: var(--orange);
  }
}
</style>
